import Observer from "@researchgate/react-intersection-observer";
import { css } from "emotion";
import React, { HTMLProps, useState } from "react";
import Graph from "./innerElements/Graph";
import Image from "./innerElements/Image";
import ImageContainer from "./innerElements/ImageContainer";
import MapQuestion from "./innerElements/MapQuestion";
import TextComponent from "./innerElements/TextComponent";
import TextQuestion from "./innerElements/TextQuestion";
import VideoElement from "./innerElements/VideoComponent";
import YoutubeLink from "./innerElements/YoutubeLink";
import TextAnswer from "./innerElements/TextAnswer";
import shuffleButton from "../../assets/next-plan-button.png";
import VideoContainer from "./innerElements/VideoContainer";

type ElementProps = Props & {
  onClickYoutube: (videoCode: string) => void;
};

const Element = (props: ElementProps & HTMLProps<HTMLDivElement>) => {
  const { element, style, onClickYoutube, ...otherProps } = props;
  const [allowedToDraw, setAllowedToDraw] = useState(true);

  const [numberOfChanges, setNumberOfChanges] = useState(0);
  const [loadingIn, setLoadingIn] = useState(true);

  function handleIntersection(event: any, unobserve: any) {
    if (loadingIn) {
      setLoadingIn(false);
    } else {
      if (numberOfChanges <= 1) {
        setAllowedToDraw(() => event.isIntersecting);
        setNumberOfChanges((prevState) => (prevState += 1));
      }
    }
  }

  const options = {
    onChange: handleIntersection,
    root: "window",
    rootMargin: "0% 0%",
  };

  const [shuffle, setShuffle] = useState(0);

  return (
    <>
      {element.shuffle && (
        <img
          src={shuffleButton}
          className={css`
            position: absolute;
            top: ${element.shuffle.y}%;
            left: ${element.shuffle.x}%;
            width: ${element.shuffle.width}%;
            cursor: pointer;
          `}
          onClick={() => {
            setShuffle(Math.random());
          }}
          alt=""
        />
      )}
      <div
        className={`${element.className ? element.className : ""} ${css`
          width: 20px;
          height: 20px;
          /* background: #000; */
          position: absolute;
        `}`}
        style={{
          left: `${element.position.x}%`,
          top: `${element.position.y}%`,
          width: `${element.size.width}%`,
          height: `${element.size.height}%`,
          ...(element.css ? element.css : {}),
          ...style,
        }}
        {...otherProps}
      >
        {element.type === "graph" && (
          <div>
            <Observer {...options}>
              <div></div>
            </Observer>
            {allowedToDraw ? <Graph element={element} /> : null}
          </div>
        )}
        {element.type === "image-container" && (
          <ImageContainer element={element} />
        )}
        {element.type === "image" && <Image element={element} />}
        {element.type === "question-text" && <TextQuestion element={element} />}
        {element.type === "map-question" && <MapQuestion element={element} />}
        {element.type === "youtube-link" && (
          <YoutubeLink element={element} onClickLink={onClickYoutube} />
        )}
        {element.type === "video" && <VideoElement element={element} />}
        {element.type === "text-answer" && (
          <TextAnswer element={element} shuffle={shuffle} />
        )}
        {element.type === "video-container" && (
          <VideoContainer element={element} shuffle={shuffle} />
        )}
        {(element.type === "text" ||
          element.type === "question" ||
          !element.type) && <TextComponent element={element} />}
        {otherProps.children}
      </div>
    </>
  );
};

export default Element;
