import React, { useState } from "react";
import Straten from "./Straten";
import Straat from "./Straat";
import closeIcon from "../../assets/icon-close.png";
import { css } from "emotion";

function Straatverklaring(props: any) {
  const [declaration, setDeclaration] = useState<Declaration | null>(null);

  const selectStraat = (declaration: Declaration) =>
    setDeclaration(declaration);
  const removeStraat = () => setDeclaration(null);

  const straatElement = declaration ? (
    <Straat declaration={declaration} click={removeStraat} />
  ) : (
    <Straten click={selectStraat} />
  );

  return (
    <div className="intro-screen intro-screen--close">
      <div className="white close big clickable" onClick={props.click}>
        <img
          className={css`
            width: 48px;
          `}
          src={closeIcon}
          alt="close"
        />
      </div>
      {declaration && (
        <div
          className="back blue clickable intro-screen__back"
          onClick={removeStraat}
        >
          {`< Andere straatverklaringen`}
        </div>
      )}
      <div className="scroll">
        <div className="inner">{straatElement}</div>
      </div>
    </div>
  );
}

export default Straatverklaring;
