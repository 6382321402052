import React, { useEffect, useState } from "react";
import ImageMapper from "react-image-mapper";
import ProgressiveImage from "react-progressive-image";
import { RouteComponentProps } from "react-router-dom";
import IntroScreen from "./IntroScreen";
import OrientationOverlay from "./OrientationOverlay";
import "./landingPage.css";
import Straatverklaring from "../Straatverklaringen/Straatverklaring";

import { useHistory } from "react-router-dom";
import { css } from "emotion";

interface LandingPageProps {
  preLaunch: boolean;
}

interface Props extends RouteComponentProps<any>, LandingPageProps {}

const LandingPage = (props: Props) => {
  const [audio] = useState(
    new Audio(
      "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_1MG.mp3"
    )
  );
  const [playing, setPlaying] = useState(false);
  const [showStraatverklaring, setShowStraatverklaring] = useState(false);
  const BASE_URL_BACKGROUND =
    "http://straatberaad-api.luuk.computer/assets/background/";
  const backgroundLayers = ["01", "02"];
  const frontLayer = "03";
  const history = useHistory();
  const routeChange = (path: string) => {
    history.push(path);
  };

  let showIntroscreen = history.action === "POP" ? true : false; // checks if user comes from same domain

  const preLaunch = props.preLaunch;

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [audio, playing]);

  const toggleStraatverklaring = () => {
    setShowStraatverklaring((prevState) => !prevState);
  };

  // Check for landscape
  const isLandscape = () =>
    window.matchMedia("(orientation:landscape)").matches;

  const [orientation, setOrientation] = useState(
    isLandscape() ? "landscape" : "portrait"
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const onWindowResize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    setOrientation(isLandscape() ? "landscape" : "portrait");
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  const MAP = {
    name: "my-map",
    areas: [
      {
        name: "bordjes",
        shape: "poly",
        coords: [550, 250, 800, 250, 800, 550, 550, 550],
      },
      {
        name: "audio",
        shape: "poly",
        coords: [850, 200, 1000, 200, 1000, 350, 850, 350],
      },
      {
        name: "straatverklaring",
        shape: "poly",
        coords: [200, 450, 400, 450, 400, 600, 200, 600],
      },
      {
        name: "agenda",
        shape: "poly",
        coords: [1000, 520, 1100, 520, 1100, 670, 1000, 670],
      },
    ],
  };

  const pauseAudio = () => {
    if (playing) {
      audio.pause();
    }
  };

  const imageClicked = (area: any) => {
    if (!preLaunch) {
      switch (area.name) {
        case "bordjes":
          pauseAudio();
          routeChange("/bordjes");
          break;
        case "audio":
          setPlaying(!playing);
          break;
        case "agenda":
          pauseAudio();
          routeChange("/agenda");
          break;
        case "straatverklaring":
          pauseAudio();
          toggleStraatverklaring();
          break;
        default:
          break;
      }
    }
  };

  const displayFrontLayer = (
    <div className="frontImage">
      <ProgressiveImage
        src={`${BASE_URL_BACKGROUND}big/background_${frontLayer}.png`}
        placeholder={`${BASE_URL_BACKGROUND}small/background_${frontLayer}.png`}
      >
        {(src: string) => {
          return (
            <ImageMapper
              src={src}
              map={MAP}
              height={windowSize.height}
              width={windowSize.height * (1474 / 931)}
              imgWidth={1474}
              fillColor="rgba(0,0,0,0)"
              strokeColor="rgba(0,0,0,0)"
              onClick={(area: any) => imageClicked(area)}
            />
          );
        }}
      </ProgressiveImage>
    </div>
  );

  const allBackgroundLayers = backgroundLayers.map(
    (layer: string, index: number) => (
      <div className="backgroundImage" key={`bg-image-${index}`}>
        <ProgressiveImage
          src={`${BASE_URL_BACKGROUND}big/background_${layer}.png`}
          placeholder={`${BASE_URL_BACKGROUND}small/background_${layer}.png`}
        >
          {(src: string) => {
            return (
              <img className={`backgroundImage${layer}`} src={src} alt="" />
            );
          }}
        </ProgressiveImage>
      </div>
    )
  );

  const InteractionLayer = (props: any) =>
    preLaunch ? (
      <div />
    ) : (
      <img
        className="interactionLayer"
        src={`${BASE_URL_BACKGROUND}big/background_04.png`}
        alt="interaction hint"
        height={windowSize.height}
        width={windowSize.height * (1474 / 931)}
        {...props}
      />
    );

  const SpeakerLayer = () =>
    playing ? (
      <img
        className="speakerLayer"
        src={`${BASE_URL_BACKGROUND}big/background_05.png`}
        alt="container"
        width={windowSize.width}
        {...props}
      />
    ) : null;

  const introscreen = showIntroscreen ? <IntroScreen /> : null;

  const landingPage =
    orientation === "portrait" ? (
      <OrientationOverlay />
    ) : (
      <>
        {introscreen}
        <div
          className={css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: ${(windowSize.width - windowSize.height * (1474 / 931)) /
            2}px;
            width: ${windowSize.height * (1474 / 931)}px;
            overflow: hidden;
          `}
        >
          {allBackgroundLayers}
          {displayFrontLayer}

          <SpeakerLayer />
          <InteractionLayer />
        </div>
      </>
    );

  const straatverklaring = showStraatverklaring ? (
    <Straatverklaring click={toggleStraatverklaring} />
  ) : null;

  return (
    <div style={{ height: "100%" }}>
      {landingPage}
      {straatverklaring}
    </div>
  );
};

export default LandingPage;
