import { css } from "emotion";
import _ from "lodash";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useState, useContext } from "react";
import ReactMapboxGl, {
  Marker,
  ZoomControl,
  Layer,
  Feature,
  GeoJSONLayer,
} from "react-mapbox-gl";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import Firebase, { FirebaseContext } from "../../../firebase";

const accessToken =
  "pk.eyJ1IjoibHV1a3NjaGlwIiwiYSI6ImNrZTRmYzRrdDAwam4yeXBvamx3b2JieWIifQ.mhkXMkiVsPVuLgmQ18XRng";

const Map = ReactMapboxGl({
  accessToken,
  scrollZoom: false,
});

const geoCode: (
  query: string,
  firebase: Firebase,
  types?: string
) => Promise<[number, number]> = (query, firebase, types = "country") => {
  console.log({ query });
  return firebase.firestore
    .collection("places")
    .where("name", "==", query)
    .limit(1)
    .get()
    .then((res) => {
      if (res.empty) {
        const mapBoxPromise = fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            query
          )}.json?access_token=${accessToken}&language=nl&types=${types}`
        )
          .then((res) => res.json())
          .then((res) => {
            console.log(res);
            return res;
          })
          .then((res) => (res.features.length ? res.features[0].center : null));
        const firebasePromise = mapBoxPromise.then((res) => {
          if (res) {
            return firebase.firestore
              .collection("places")
              .add({ name: query, lat: res[0], lng: res[1] })
              .then(() => res);
          } else {
            return Promise.resolve();
          }
        });
        return Promise.all([mapBoxPromise, firebasePromise]).then((res) =>
          res ? res[0] : null
        );
      } else {
        const data = res.docs[0].data();
        return [data.lat, data.lng] as [number, number];
      }
    });
};

function splitMulti(str: string, tokens: string[]) {
  var tempChar = tokens[0]; // We can use the first token as a temporary join character
  for (var i = 1; i < tokens.length; i++) {
    str = str.split(tokens[i]).join(tempChar);
  }
  return str.split(tempChar);
}

const MapComponent = (props: {
  places: string[];
  mapStyle: string;
  splitPlacesOn?: string[];
  queryTypes?: string;
  mapColor?: string;
}) => {
  const { places, mapStyle, splitPlacesOn, queryTypes, mapColor } = props;
  const [positions, setPositions] = useState<
    { name: string; coordinates: [number, number] }[]
  >([]);
  const [styleLoaded, setStyleLoaded] = useState(false);
  useCollectionOnce();
  const firebase = useContext(FirebaseContext);
  useEffect(() => {
    Promise.all(
      _.flattenDeep(
        places.map((place) =>
          splitMulti(place, splitPlacesOn ? splitPlacesOn : [])
        )
      )
        .map((place) => {
          place = place.toLowerCase();
          switch (place) {
            case "de vs":
            case "vs":
            case "amerika":
              place = "usa";
              break;
          }
          return place;
        })
        .map((res) =>
          geoCode(res, firebase, queryTypes).then((coordinates) =>
            res
              ? {
                  name: res,
                  coordinates,
                }
              : null
          )
        )
    )
      .then((res) => {
        console.log(res);
        return res;
      })
      .then((res) => setPositions(_.compact(res)));
  }, [places, firebase]);
  return (
    <>
      {!styleLoaded && (
        <div
          style={{ width: "100%", height: "100%", background: "#666" }}
        ></div>
      )}
      <Map
        style={mapStyle}
        containerStyle={{
          height: "100%",
          width: "100%",
          opacity: styleLoaded ? 1 : 0,
        }}
        center={[-1.481747846041145, 51.3233379650232]}
        zoom={[0]}
        onStyleLoad={(e) => {
          e.resize();
          setStyleLoaded(true);
        }}
      >
        <ZoomControl />
        <GeoJSONLayer
          sourceOptions={{
            cluster: true,
            clusterMaxZoom: 15, // Max zoom to cluster points on
            clusterRadius: 40,
          }}
          id="source_id"
          data={{
            type: "FeatureCollection",
            crs: {
              type: "name",
              properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
            },
            features: positions.map((position, i) => ({
              type: "Feature",
              properties: {
                id: `feature-${i}`,
                name: position.name,
              },
              geometry: {
                type: "Point",
                coordinates: position.coordinates,
              },
            })),
          }}
        />
        <Layer
          id="cluster_layer"
          sourceId="source_id"
          paint={{
            "circle-stroke-color": "#000000",
            "circle-radius": 15,
            "circle-color": mapColor ? mapColor : "#ff0000",
            "circle-stroke-width": 2,
          }}
          type="circle"
        />
        {/* // Layer of unclustered spots */}
        <Layer
          id="unclustered_layer"
          sourceId="source_id"
          layerOptions={{
            filter: ["!", ["has", "point_count"]],
          }}
          type="symbol"
          layout={{
            "text-field": "1",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          }}
        />
        {/* // Layer of cluster points */}
        <Layer
          id="cluster_count"
          type="symbol"
          sourceId="source_id"
          layerOptions={{
            filter: ["has", "{point_count}"],
          }}
          layout={{
            "text-field": "{point_count}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          }}
        />
      </Map>
    </>
  );
};

export default MapComponent;
