import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../firebase";

function Straten(props: any) {
  const firebase = useContext(FirebaseContext);
  const [declarations, setDeclarations] = useState<Declaration[]>([]);
  useEffect(() => {
    firebase.firestore
      .collection("events")
      .get()
      .then((events) => {
        console.log({ events: events.docs });
        return Promise.all(
          events.docs.map((event) => event.ref.collection("declarations").get())
        );
      })
      .then((results) => {
        return results
          .map((result) =>
            result.docs
              .filter((result) => !result.data().hide)
              .map((result) => result.data().declaration)
          )
          .filter((docs) => docs.length);
      })
      .then((results) => {
        console.log({ results });
        setDeclarations(_.flatten(results));
      });
  }, []);
  return (
    <div className="straatverklaring">
      <div className="blue bold">STRAATVERKLARINGEN</div>
      {declarations.length === 0 && <div>Laden...</div>}
      {declarations.map((declaration, i) => (
        <div>
          <span
            key={`declaration-${i}`}
            className="clickable"
            onClick={() => props.click(declaration)}
          >
            <span className="bold">{declaration[4].phrase_text}, </span>
            <span>&nbsp;{declaration[6].phrase_text}</span>
          </span>
        </div>
      ))}
    </div>
  );
}

export default Straten;
