import React from "react";
import MapComponent from "../components/Elements/innerElements/MapComponent";

const MapTest = () => {
  return (
    <div>
      <MapComponent
        places={["boekel", "china"]}
        mapStyle="mapbox://styles/luukschip/cke4m39fi1aod19pfctvsi5yy"
      />
    </div>
  );
};

export default MapTest;
