import React, {
  HTMLProps,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
// import { FirebaseContext } from "../firebase";
import { FirebaseContext } from "../../../firebase";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import playIcon from "../../../assets/icon-play.png";
import { css } from "emotion";

function getRandom(arr: any[], n: number) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

const Video = (props: any) => {
  const { style, ...otherProps } = props;
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (playing) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [playing]);
  return (
    <div
      onClick={() => setPlaying(!playing)}
      style={{ position: "relative", ...style }}
    >
      <video
        ref={videoRef}
        {...otherProps}
        className={css`
          width: 100%;
        `}
      />
      {!playing && (
        <img
          src={playIcon}
          alt="play video"
          className={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 25%;
            max-width: 48px;
          `}
        />
      )}
    </div>
  );
};

const VideoContainer = (
  props: { shuffle: number } & Props & HTMLProps<HTMLDivElement>
) => {
  const {
    element: { questionId },
    shuffle,
  } = props;
  const firebase = useContext(FirebaseContext);
  const [answers] = useCollectionDataOnce<VideoAnswer>(
    firebase.firestore
      .collection("collected_data")
      .where("question_id", "==", questionId ? questionId : "")
  );
  const [answerIndex, setAnswerIndex] = useState(0);
  useEffect(() => {
    if (answers && answers.length > 1) {
      let newIndex = Math.floor(Math.random() * answers.length);
      console.log(1, newIndex);
      while (newIndex === answerIndex || !answers[newIndex].answer_media_uri) {
        console.log(2, newIndex);
        newIndex = Math.floor(Math.random() * answers.length);
      }
      console.log(3, newIndex);
      console.log(answers[newIndex]);
      setAnswerIndex(newIndex);
    }
  }, [shuffle]);

  return (
    <>
      {answers && answers.length && (
        <Video
          style={{
            width: "100%",
          }}
          src={answers[answerIndex].answer_media_uri}
        />
      )}
    </>
  );
};

export default VideoContainer;
