import React, { useState } from "react";
import Colophon from "./Colophon";
import { css } from "emotion";
import iconBack from "../../assets/icon-back.png";

function NavigationMenu() {
  const [showMenu, setShowMenu] = useState("hideMenu");
  const [showHamburger, setShowHamburger] = useState("hideHamburger");

  const toggleMenu = () => {
    setShowMenu((prevState: string) =>
      prevState === "hideMenu" ? "showMenu" : "hideMenu"
    );
    setShowHamburger((prevState: string) =>
      prevState === "hideHamburger" ? "showHamburger" : "hideHamburger"
    );
    setOpenItem(null);
  };

  const [openItem, setOpenItem] = useState<string | null>(null);

  const ShowHiddenLayer = () =>
    showMenu === "showMenu" ? (
      <>
        <div className="hidden" onClick={toggleMenu}></div>
      </>
    ) : null;

  const Menu = () => (
    <>
      <div className={`menu ${showMenu}`}>
        {openItem && (
          <div
            onClick={() => setOpenItem(null)}
            className={css`
              position: absolute;
              top: 40px;
              left: 32px;
              color: white;
              cursor: pointer;
            `}
          >
            <img
              className={css`
                height: 32px;
              `}
              src={iconBack}
              alt="back"
            />
          </div>
        )}
        <div className="scroll">
          <div
            className={css`
              padding: 0 0 64px 0;
            `}
          >
            {!openItem && (
              <>
                <div className="menuItem" onClick={() => setOpenItem("about")}>
                  <div className="boldWord">Over</div> Het grote straatberaad
                </div>
                <div className="menuItem">
                  <div
                    className="boldWord"
                    onClick={() => setOpenItem("colophon")}
                  >
                    Credits
                  </div>
                </div>
                <div className="menuItem">
                  <div
                    className="boldWord"
                    onClick={() => setOpenItem("inschrijven")}
                  >
                    Inschrijven
                  </div>
                </div>
              </>
            )}
            {openItem === "colophon" && (
              <div
                className={css`
                  padding: 32px 32px 32px 0;
                `}
              >
                <h2>Credits</h2>
                <p>
                  Het Grote Straatberaad is onderdeel van Warming Up, een
                  campagne van Stichting Impact Makers. Meer informatie over
                  deze campagne vind je op{" "}
                  <a href="https://wearewarmingup.nl">www.wearewarmingup.nl</a>
                </p>
                <p>
                  <strong>CONCEPT EN REGIE</strong>
                  <br></br>
                  Karlijn Benthem, Vera Born en Matthea de Jong
                </p>
                <p>
                  <strong>DRAMATURGIE</strong>
                  <br></br>
                  Tomas Leijen
                </p>
                <p>
                  <strong>GELUIDSONTWERP</strong>
                  <br />
                  George Dhauw
                </p>
                <p>
                  <strong>ASSISTENTIE</strong>
                  <br></br>
                  Merijn Viergever
                </p>
                <p>
                  <strong>STEMMEN</strong>
                  <br></br>
                  Dionne Verwey en Emmanuel Boafo
                </p>
                <p>
                  <strong>PRODUCTIE</strong>
                  <br></br>
                  Mattie Boekema, Floortje Halters en Warming Up
                </p>
                <p>
                  <strong>WEB-APP</strong>
                  <br></br>
                  Fabian Hijlkema
                  <br />
                </p>
                <p>
                  <strong>WEBSITE</strong>
                  <br />
                  Luuk Schipperheyn en Ibo Ibelings (development)
                  <br />
                  Ruiter Janssen (design)
                </p>
              </div>
            )}
            {openItem === "about" && (
              <div
                className={css`
                  padding: 32px 32px 32px 0;
                `}
              >
                <h2>Over Het Grote Straatberaad</h2>
                <p>
                  Het klimaat verandert. Niet morgen, maar nu. De beslissingen
                  die we de komende jaren maken bepalen hoe de wereld eruit komt
                  te zien. Dit kunnen we niet alleen aan de politiek over laten.
                  Het is tijd om ook onze stem laten horen. De komende maanden
                  spelen we daarom door heel Nederland Het Grote Straatberaad,
                  een volksvergadering over het klimaat in de vorm van een
                  spannend spel.
                </p>
                <p>
                  In twee uur tijd onderzoek je samen met je medespelers hoe we
                  onze toekomst vorm willen geven. Je voert opdrachten uit,
                  overlegt, discussieert en beantwoordt vragen. Je bouwt zo
                  samen aan een Straatverklaring die je aan het einde van het
                  spel krijgt.
                </p>
                <p>
                  De middag wordt geleid door een zelfgekozen Straatcoach en
                  Straatomroeper, met behulp van een pratende (elektrische)
                  auto. Je krijgt van ons een kist met alle benodigdheden om het
                  spel te spelen. Via een online omgeving vul je je antwoorden
                  in. Deze verzamelen wij voor de eerste bottom-up
                  Klimaatverklaring van Nederland: The Future We Want, die we
                  begin november aan het kabinet overhandigen.
                </p>
              </div>
            )}
            {openItem === "inschrijven" && (
              <div
                className={css`
                  padding: 32px 32px 32px 0;
                `}
              >
                <h2>Inschrijven</h2>
                <p>
                  Wil jij ook met jouw straat, buurt, dorp, camping,
                  vriendengroep of sportclub mee praten over het grootste
                  vraagstuk van deze tijd? Schrijf je dan in voor Het Grote
                  Straatberaad en laat je stem horen!
                </p>
                <p>
                  Wat je nodig hebt:
                  <ul>
                    <li>Een zelfgekozen Straatcoach en Straatomroeper.</li>
                    <li>Ongeveer 10 - 30 spelers.</li>
                    <li>Een (elektrische) auto.</li>
                    <li>Opgeladen telefoons.</li>
                  </ul>
                </p>
                <p>
                  Je inschrijven kan via dit formulier, ook als je nog niet aan
                  bovenstaande punten voldoet. Mocht het niet lukken om een van
                  de bovenstaande punten te regelen, vraag ons dan vooral om
                  hulp! Zie deze pagina voor contactgegevens. Wil je graag als
                  individu ergens aanhaken? Of woon je buiten de regio’s genoemd
                  op deze website? Stuur ons dan een mailtje via
                  <a href="mailto:info@wearewarmingup.nl">
                    info@wearewarmingup.nl
                  </a>{" "}
                  en wij zorgen dat je mee kunt spelen.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <nav role="navigation">
      <div className={`menuToggle ${showHamburger}`} onClick={toggleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <Menu />
      <ShowHiddenLayer />

      {/* <a href="#"><li><div className="boldWord" onClick={() => setShowColophon((show) => !show)}> Colophon</div></li></a>
        {ColophonShow(showColophon)} */}
    </nav>
  );
}

export default NavigationMenu;
