import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

import Bordjes from "./components/LandingPage/Borden";
import ChapterComponent from "./components/Chapter/chapter";
import ChapterEditorComponent from "./components/Chapter/chapter-editor";
import Firebase, { FirebaseContext } from "./firebase";
import LandingPage from "./components/LandingPage/landingPage";
import pieChartTest from "./tests/pieChartTest";
import MapTest from "./tests/MapTest";
import QuestionTest from "./tests/QuestionTest";
import VideoTest from "./tests/VideoTest";
import NavigationMenu from "./components/Navigation/NavigationMenu";
import Agenda from "./components/LandingPage/Agenda";
import LOGO_STRAATBERAAD from "./assets/logo.png";

const preLaunch = false;
function App() {
  return (
    <div className="App">
      {!preLaunch && <NavigationMenu />}
      <FirebaseContext.Provider value={new Firebase()}>
        <BrowserRouter>
          <Link to="/">
            <img
              src={LOGO_STRAATBERAAD}
              className="logo"
              alt="logo straatberaad"
            />
          </Link>

          <Route
            path="/"
            render={(props) => <LandingPage {...props} preLaunch={preLaunch} />}
            exact
          ></Route>
          <Route path="/bordjes" component={Bordjes}></Route>
          <Route path="/agenda" component={Agenda} exact></Route>

          <Route
            path="/chapter/:chapterName"
            component={ChapterComponent}
            exact
          ></Route>

          <Route
            path="/chapter/:chapterName/edit"
            component={ChapterEditorComponent}
            exact
          ></Route>

          <Route path="/pieChartTest" component={pieChartTest} exact></Route>
          <Route path="/mapTest" component={MapTest} exact></Route>
          <Route path="/questionTest" component={QuestionTest} exact></Route>
          <Route path="/videoTest" component={VideoTest} exact></Route>
        </BrowserRouter>
      </FirebaseContext.Provider>
    </div>
  );
}

export default App;
