import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import API from "../../api";
import Element from "../Elements/element";
import iconClose from "../../assets/icon-close.png";

import HorizontalScroll from "react-scroll-horizontal";
import { css } from "emotion";

const ChapterContainer = styled.div`
  height: 100%;
  position: relative;
  display: inline-block;
`;

const Background = styled.img`
  height: 100%;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
`;

const ChapterComponent = (props: RouteComponentProps<any>) => {
  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState<any[]>([]);
  const [background, setBackground] = useState<string | null>();
  const container = useRef<HTMLDivElement>(null);
  const { chapterName } = props.match.params;
  const [youtubeVideoCode, setYoutubeVideoCode] = useState<string | null>(null);

  window.ondragstart = function () {
    return false;
  };

  useEffect(() => {
    setLoading(true);
    API.getChapter(chapterName).then((chapter: any) => {
      setElements(chapter.elements ? chapter.elements : []);
      setBackground(chapter.background);
      setLoading(false);
    });
  }, [chapterName]);

  const YoutubeFrame = (code: string) => (
    <div
      className={css`
        position: fixed;
        background: #000;
        bottom: 0;
        left: 0;
      `}
    >
      <div
        className={css`
          background: #000;
          display: flex;
          justify-content: flex-end;
        `}
      >
        <img
          src={iconClose}
          onClick={() => setYoutubeVideoCode(null)}
          alt="close video"
          className={css`
            height: 16px;
            padding: 4px;
            cursor: pointer;
          `}
        />
      </div>
      <iframe
        width="200"
        height="100"
        src={`https://www.youtube.com/embed/${code}?autoplay=1&controls=0&modestbranding=1&playsinline=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );

  if (loading) {
    return <div>loading...</div>;
  }

  if ("ontouchstart" in document.documentElement) {
    return (
      <>
        <ChapterContainer ref={container}>
          {background && <Background src={background} alt="background" />}
          {elements.map((element, index) => (
            <Element
              key={`element-${index}`}
              element={element}
              onClickYoutube={(code) => {
                setYoutubeVideoCode(code);
              }}
            />
          ))}
        </ChapterContainer>
        {youtubeVideoCode && YoutubeFrame(youtubeVideoCode)}
      </>
    );
  } else {
    return (
      <>
        <HorizontalScroll reverseScroll={true} pageLock={false}>
          {[
            <ChapterContainer ref={container} key="scroll-child-0">
              {background && (
                <Background id="background" src={background} alt="background" />
              )}
              {elements.map((element, index) => (
                <Element
                  key={`element-${index}`}
                  element={element}
                  onClickYoutube={(code) => {
                    setYoutubeVideoCode(code);
                  }}
                />
              ))}
            </ChapterContainer>,
          ]}
        </HorizontalScroll>
        {youtubeVideoCode && YoutubeFrame(youtubeVideoCode)}
      </>
    );
  }
};
export default ChapterComponent;
