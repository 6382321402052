import React, { HTMLProps, useState, useContext, useEffect } from "react";
// import { FirebaseContext } from "../firebase";
import { FirebaseContext } from "../../../firebase";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { filter } from "lodash";

function getRandom(arr: any[], n: number) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}
const ImageContainer = (props: Props & HTMLProps<HTMLDivElement>) => {
  const {
    element: { questionId, columns },
  } = props;
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [widths, setWidths] = useState<string[]>();
  const [margins, setMargins] = useState<number[]>();
  const [tops, setTops] = useState<number[]>();
  const [lefts, setLefts] = useState<number[]>();
  const [images, setImages] = useState<string[]>([]);
  const firebase = useContext(FirebaseContext);
  const [answers] = useCollectionDataOnce<ImageAnswer>(
    firebase.firestore
      .collection("collected_data")
      .where("question_id", "==", questionId ? questionId : "")
  );
  const [answersLoaded, setAnswersLoaded] = useState(false);
  useEffect(() => {
    if (answers && !answersLoaded) {
      const filteredAnswers = answers.filter(
        (answer) =>
          answer.answer_media_uri !==
          "https://media.hetgrotestraatberaad.nl/File extension was other than .jpg, .png, .gif or .mp4"
      );
      setImages(
        getRandom(
          filteredAnswers,
          8 > filteredAnswers.length ? filteredAnswers.length : 8
        ).map((answer) => answer.answer_media_uri)
      );
      setWidths(
        answers.map(() =>
          columns ? `${100 / columns - 5}%` : `${15 + Math.random() * 15}%`
        )
      );
      setMargins(answers.map(() => Math.random() * 10));
      setTops(answers.map(() => Math.random() * 60 - 30));
      setLefts(answers.map(() => Math.random() * 20 - 10));
      setAnswersLoaded(true);
    }
  }, [answers, answersLoaded, columns]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "baseline",
        }}
      >
        {widths &&
          margins &&
          tops &&
          lefts &&
          images.map((image: string, index: number) => (
            <img
              key={`image${index}`}
              style={{
                width: widths[index],
                margin: margins[index],
                position: "relative",
                top: tops[index],
                left: lefts[index],
                opacity: 0.9,
              }}
              onClick={() => setSelectedImage(image)}
              src={image}
              alt=""
            />
          ))}
      </div>
      {selectedImage && (
        <img
          src={selectedImage}
          style={{
            position: "fixed",
            top: 20,
            /*            left: 20,*/
            height: "calc(100vh - 40px)",
          }}
          alt=""
          onClick={() => setSelectedImage(null)}
        />
      )}
    </>
  );
};

export default ImageContainer;
