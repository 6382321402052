import React, {
  HTMLProps,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Pie } from "react-chartjs-2";
import { FirebaseContext } from "../../../firebase";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import _ from "lodash";
import { css } from "emotion";
import { mq } from "../../../utils/media-query";

const colorSchemes = [
  ["#3817ab", "#2f6fff", "#00acff", "#00a67e", "#056a24"],
  ["#d52618", "#e70696", "#9c002a", "#e6b016", "#ff8100"],
];

type QuestionData = {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
    borderWidth: number;
  }[];
  question: string;
};
type AnswersForQuestionData = {
  [index: number]: {
    label: string;
    amount: number;
  };
};

function createQuestionData(
  answers: AnswersForQuestionData,
  question: string,
  colorScheme: number
): QuestionData {
  return {
    labels: _.map(answers, "label"),
    datasets: [
      {
        data: _.map(answers, "amount"),
        backgroundColor: colorSchemes[colorScheme].map((color) => color),
        borderWidth: 0,
      },
    ],
    question: question,
  };
}

const getTextSize = () => {
  if (window.innerHeight < 500) {
    return 10;
  } else {
    return 12;
  }
};

const getPaddingSize = () => {
  if (window.innerHeight < 500) {
    return 4;
  } else {
    return 4;
  }
};

const Graph = (props: Props & HTMLProps<HTMLDivElement>) => {
  const { element } = props;
  const labelStyle = element.labelStyle ? element.labelStyle : {};
  const firebase = useContext(FirebaseContext);
  const [answers] = useCollectionDataOnce<MultipleChoiceAnswer>(
    firebase.firestore
      .collection("collected_data")
      .where("question_id", "==", element.questionId ? element.questionId : "")
  );
  const [answersLoaded, setAnswersLoaded] = useState(false);
  const [questionData, setQuestionData] = useState<QuestionData>();

  const [textSize, setTextSize] = useState(getTextSize());
  const [paddingSize, setPaddingSize] = useState(getPaddingSize());
  const [colorScheme, setColorScheme] = useState(0);

  const pieRef = useRef<Pie>(null);

  if (colorScheme !== element.colorScheme) {
    setColorScheme(() => element.colorScheme);
  }

  window.onresize = function (event: any) {
    setTextSize(() => getTextSize());
    setPaddingSize(() => getPaddingSize());
  };

  const options = {
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };

  useEffect(() => {
    if (!answersLoaded) {
      if (!answers) {
        return;
      }
      setAnswersLoaded(true);
      const questionData = createQuestionData(
        answers.reduce((acc, answer) => {
          if (!acc[answer.answer_choice_index]) {
            acc[answer.answer_choice_index] = {
              label: answer.answer_text,
              amount: 0,
            };
          }
          acc[answer.answer_choice_index].amount += 1;
          return acc;
        }, {} as AnswersForQuestionData),
        "question text",
        colorScheme
      );
      setQuestionData(questionData);
    }
  }, [answers, answersLoaded, colorScheme]);

  const [legendRendered, setLegendRendered] = useState(false);

  const plugins = [
    {
      afterDatasetsDraw() {
        // hack to force re-render component in order to show legend
        if (!legendRendered) {
          setLegendRendered(true);
        }
      },
    },
  ];
  return (
    <>
      {legendRendered && pieRef.current && (
        <div
          className={css`
            margin-bottom: ${labelStyle.marginBottom
              ? labelStyle.marginBottom
              : "16px"};
            background: ${labelStyle.containerBackground
              ? labelStyle.containerBackground
              : "none"};
            padding: ${labelStyle.containerPadding
              ? labelStyle.containerPadding
              : "0px"};
          `}
        >
          {pieRef.current.chartInstance.legend.legendItems.map(
            (item: any, i: number) => (
              <div
                key={`label-${i}`}
                className={css`
                  display: flex;
                  align-items: flex-start;
                  margin-bottom: 8px;
                `}
              >
                <div
                  className={css`
                    width: 15px;
                    height: 15px;
                    background: ${item.fillStyle};
                    margin: 0px 8px 2px 0;
                    display: inline-block;
                    ${mq()} {
                      width: 8px;
                      height: 8px;
                      margin-right: 4px;
                    }
                  `}
                />
                <div
                  className={css`
                    flex: 1;
                    color: ${labelStyle.color ? labelStyle.color : "white"};
                    ${mq()} {
                      font-size: 8px;
                    }
                    line-height: 1;
                    &::first-letter {
                      text-transform: capitalize;
                    }
                  `}
                >
                  {item.text}
                </div>
              </div>
            )
          )}
        </div>
      )}
      {questionData && (
        <Pie
          ref={pieRef}
          plugins={plugins}
          width={element.dimensions[0]}
          height={element.dimensions[1]}
          data={questionData}
          options={options}
        />
      )}
    </>
  );
};

export default Graph;
