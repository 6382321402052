import React, { HTMLProps, useContext, useState, useEffect } from "react";
import { FirebaseContext } from "../../../firebase";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import MapComponent from "./MapComponent";

const MapQuestion = (props: Props & HTMLProps<HTMLDivElement>) => {
  const {
    element: { questionId, mapStyle, splitPlacesOn, queryTypes, mapColor },
  } = props;
  const firebase = useContext(FirebaseContext);
  const [answers] = useCollectionDataOnce<MultipleChoiceAnswer>(
    firebase.firestore
      .collection("collected_data")
      .where("question_id", "==", questionId ? questionId : "")
  );
  const [answersLoaded, setAnswersLoaded] = useState(false);
  const [places, setPlaces] = useState<string[]>([]);
  useEffect(() => {
    if (!answersLoaded) {
      if (!answers) {
        return;
      }
      setAnswersLoaded(true);
      setPlaces(answers.map((answer) => answer.answer_text));
    }
  }, [answers, answersLoaded]);
  return (
    <MapComponent
      places={places}
      mapStyle={
        mapStyle
          ? mapStyle
          : "mapbox://styles/luukschip/cke4fw17714pk19pal3kl1rfv"
      }
      splitPlacesOn={splitPlacesOn}
      queryTypes={queryTypes}
      mapColor={mapColor}
    />
  );
};

export default MapQuestion;
