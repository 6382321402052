import React, { useState, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import ImageMapper from "react-image-mapper";
import "./landingPage.css";
import { css } from "emotion";

const URL_FRONT = `http://straatberaad-api.luuk.computer/assets/bordjes/bordjes_front.png`;
const URL_BG = `http://straatberaad-api.luuk.computer/assets/bordjes/bordjes_bg.png`;

const Bordjes = (props: RouteComponentProps<any>) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const history = useHistory();

  const signClicked = (area: any) => history.push(`chapter/${area.name}`);

  const MAP = {
    name: "my-map",
    areas: [
      {
        name: "economie",
        shape: "poly",
        coords: [500, 470, 800, 490, 800, 560, 500, 540],
      },
      {
        name: "mobiliteit",
        shape: "poly",
        coords: [500, 375, 800, 395, 800, 465, 500, 445],
      },
      {
        name: "democratie",
        shape: "poly",
        coords: [500, 275, 800, 295, 800, 375, 500, 355],
      },
      {
        name: "gezondheid",
        shape: "poly",
        coords: [500, 175, 800, 195, 800, 275, 500, 255],
      },
      {
        name: "introductie",
        shape: "poly",
        coords: [500, 75, 800, 95, 800, 180, 500, 160],
      },
    ],
  };

  const frontLayer = (
    <div className="frontImage">
      <ImageMapper
        src={URL_FRONT}
        map={MAP}
        height={windowSize.height}
        width={windowSize.height * (1243 / 702)}
        imgWidth={1243}
        fillColor="rgba(0,0,0,0)"
        strokeColor="rgba(0,0,0,0)"
        onClick={(area: any) => signClicked(area)}
      />
    </div>
  );

  const backgroundLayer = (
    <img
      className="backgroundImage01"
      src={URL_BG}
      alt="background layer"
      height={windowSize.height}
    />
  );
  return (
    <div
      className={css`
        position: absolute;
        top: 0;
        bottom: 0;
        left: ${(windowSize.width - windowSize.height * (1243 / 702)) / 2}px;
        width: ${windowSize.height * (1243 / 702)}px;
        overflow: hidden;
      `}
    >
      {backgroundLayer}
      {frontLayer}
    </div>
  );
};

export default Bordjes;
