import * as firebase from "firebase/app";
import "firebase/firestore";
import React from "react";
import config from "./config";

class Firebase {
  public app: firebase.app.App;
  public firestore: firebase.firestore.Firestore;
  constructor() {
    this.app = firebase.initializeApp(config.firebase);
    this.firestore = firebase.firestore(this.app);
  }
}
const FirebaseContext = React.createContext<Firebase>({} as Firebase);

export default Firebase;
export { FirebaseContext };
