import React from "react";
import { css } from "emotion";

function Straat({
  declaration,
  click,
}: {
  declaration: Declaration;
  click: () => void;
}) {
  return (
    <div className="verklaring-container">
      <span className="big verklaring">{`De Straatverklaring van ${declaration[4].phrase_text}`}</span>
      {declaration.map((declarationItem, i) => (
        <span
          key={`declarationItem-${i}`}
          className={`verklaring verklaring--${declarationItem.phrase_type}`}
        >
          {declarationItem.phrase_text}{" "}
        </span>
        // {declarationItem.phrase_text === "." && (
        //   <div
        //     className={css`
        //       margin-bottom: 16px;
        //     `}
        //   ></div>
        // )}
      ))}
    </div>
  );
}

export default Straat;
