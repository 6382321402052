import styled from "@emotion/styled";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import API from "../../api";
import Element from "../Elements/element";
// import hasClass from "./utils/has-class";
import usePrevious from "../../utils/use-previous";

const ChapterContainer = styled.div`
  height: 500px;
  position: relative;
  display: inline-block;
`;

const Background = styled.img`
  height: 100%;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
`;

const ChapterEditorComponent = (props: RouteComponentProps<any>) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [elements, setElements] = useState<any[]>([]);
  const [background, setBackground] = useState<string | null>();
  const [selectedElementIndex, setSelectedElementIndex] = useState<
    number | null
  >(null);
  const elementTypeSelect = useRef<HTMLSelectElement>(null);
  const fileField = useRef<HTMLInputElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const { chapterName } = props.match.params;
  const [JSONText, setJSONText] = useState<string>();
  const previous = usePrevious({ JSONText });
  const [autoUpdate, setAutoUpdate] = useState(false);
  const updateElements = useCallback(() => {
    if (
      selectedElementIndex === null ||
      !JSONText ||
      JSONText === previous?.JSONText
    ) {
      return;
    }
    setElements([
      ...elements.slice(0, selectedElementIndex),
      JSON.parse(JSONText),
      ...elements.slice(selectedElementIndex + 1),
    ]);
    // eslint-disable-next-line
  }, [selectedElementIndex, JSONText, elements, previous?.JSONText, previous]);

  useEffect(() => {
    setLoading(true);
    API.getChapter(chapterName).then((chapter: any) => {
      setElements(chapter.elements ? chapter.elements : []);
      setBackground(chapter.background);
      setLoading(false);
    });
  }, [chapterName]);

  useEffect(() => {
    if (elements && elements.length && selectedElementIndex !== null) {
      setJSONText(JSON.stringify(elements[selectedElementIndex], null, 2));
    }
  }, [selectedElementIndex, elements]);

  useEffect(() => {
    if (autoUpdate) {
      updateElements();
    }
  }, [JSONText, autoUpdate, updateElements]);

  async function save() {
    setSaving(true);
    await API.saveChapter(chapterName, {
      background,
      elements,
    });
    setSaving(false);
  }

  async function uploadBackground() {
    if (!fileField.current) {
      return;
    }
    if (!fileField.current.files) {
      return;
    }
    const file = fileField.current.files[0];
    setBackground(await API.upload(file));
  }

  function addElement() {
    if (!elementTypeSelect.current) {
      return;
    }
    const type = elementTypeSelect.current.value;
    const elementBase = {
      type,
      position: { x: 0, y: 0 },
      size: { width: 5, height: 5 },
      text: "",
      css: {},
      dimensions: [1, 1],
      textStyle: { "font-size": "6px" },
    };
    let newElement;
    switch (type) {
      case "graph": {
        newElement = {
          ...elementBase,
          questionId: "",
          colorScheme: 0,
          labelStyle: {
            color: "#000000",
          },
        };
        break;
      }
      case "image-container": {
        newElement = { ...elementBase, questionId: "" };
        break;
      }
      case "video-container": {
        newElement = { ...elementBase, questionId: "" };
        break;
      }
      case "text-answer": {
        newElement = { ...elementBase, questionId: "" };
        break;
      }
      case "image": {
        // dit zijn nog even een paar variablen voor een randon filler image
        const min = 100;
        const max = 300;
        const x = Math.floor(Math.random() * (max - min) + min);
        const y = Math.floor(Math.random() * (max - min) + min);

        newElement = {
          ...elementBase,
          imageUrl: `https://www.fillmurray.com/g/${x}/${y}`,
        };
        break;
      }
      case "question-text":
      case "map-question": {
        newElement = { ...elementBase, questionId: "" };
        break;
      }
      case "youtube-link": {
        newElement = { ...elementBase, youtubeUrl: "" };
        break;
      }
      case "video": {
        newElement = { ...elementBase, videoLink: "" };
        break;
      }
      case "question": {
        newElement = {
          ...elementBase,
          size: {
            width: 5,
            height: 25,
          },
          css: {
            color: "rgb(227, 173, 22)",
          },
          textStyle: {
            "font-size": "8px",
          },
          text: "question",
          className: "questionLabel",
        };
        break;
      }
      default: {
        newElement = elementBase;
        break;
      }
    }
    setElements([...elements, newElement]);
  }

  function deleteElement(selectedElementIndex: number) {
    return elements
      .slice(0, selectedElementIndex)
      .concat(elements.slice(selectedElementIndex + 1, elements.length));
  }

  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <div style={{ background: "#fff" }}>
      <ChapterContainer ref={container}>
        {background && (
          <Background
            className="editor-background"
            src={background}
            alt="background"
          />
        )}

        {elements.map((element, index) => {
          let held = false;
          let heldResize = false;
          function onMouseMove(e: any) {
            if (!container.current || !held) {
              return;
            }
            const {
              clientWidth: containerWidth,
              clientHeight: containerHeight,
            } = container.current;
            const x =
              ((e.clientX + document.querySelector(".App")!.scrollLeft) /
                containerWidth) *
              100;
            const y = (e.clientY / containerHeight) * 100;
            setElements([
              ...elements.slice(0, index),
              {
                ...elements[index],
                position: {
                  x,
                  y,
                },
              },
              ...elements.slice(index + 1),
            ]);
            e.stopPropagation();
            e.preventDefault();
          }
          function onMouseMoveResize(e: any) {
            if (!container.current || !heldResize) {
              return;
            }
            const {
              clientWidth: containerWidth,
              clientHeight: containerHeight,
            } = container.current;
            const x = elements[index].position.x;
            const y = elements[index].position.y;
            const mouseX =
              ((e.clientX + document.querySelector(".App")!.scrollLeft) /
                containerWidth) *
              100;
            const mouseY = (e.clientY / containerHeight) * 100;
            const width = mouseX - x;
            const height = mouseY - y;
            setElements([
              ...elements.slice(0, index),
              {
                ...elements[index],
                size: {
                  width,
                  height,
                },
              },
              ...elements.slice(index + 1),
            ]);
            e.stopPropagation();
            e.preventDefault();
          }
          function onMouseUp() {
            if (container && container.current) {
              container.current.removeEventListener("mousemove", onMouseMove);
            }
            held = false;
          }
          function onMouseUpResize() {
            if (container && container.current) {
              container.current.removeEventListener(
                "mousemove",
                onMouseMoveResize
              );
            }
            heldResize = false;
          }
          return (
            <Element
              key={`element-${index}`}
              element={element}
              onClick={() => setSelectedElementIndex(index)}
              onClickYoutube={(e) => {}}
              onMouseDown={() => {
                held = true;
                if (!container.current) {
                  return;
                }
                container.current.addEventListener("mousemove", onMouseMove);
                container.current.addEventListener("mouseup", onMouseUp);
              }}
              style={{ userSelect: "none" }}
            >
              <span
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  width: 10,
                  height: 10,
                  background: "#fff",
                  display: "block",
                }}
                onMouseDown={(e) => {
                  heldResize = true;
                  if (!container.current) {
                    return;
                  }
                  container.current.addEventListener(
                    "mousemove",
                    onMouseMoveResize
                  );
                  container.current.addEventListener(
                    "mouseup",
                    onMouseUpResize
                  );
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </Element>
          );
        })}
      </ChapterContainer>
      <div>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            background: "#fff",
          }}
        >
          {selectedElementIndex !== null && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button onClick={updateElements}>update</button>
                <span>
                  <input
                    id="autoUpdate"
                    type="checkbox"
                    checked={autoUpdate}
                    onChange={(e) => setAutoUpdate(e.target.checked)}
                  />
                  <label htmlFor="autoUpdate">auto update</label>
                </span>
                <button
                  onClick={() =>
                    setElements(() => deleteElement(selectedElementIndex))
                  }
                >
                  delete element
                </button>
                <button onClick={() => setSelectedElementIndex(null)}>
                  close
                </button>
              </div>
              <textarea
                id={`json-editor-${selectedElementIndex}`}
                value={JSONText}
                rows={15}
                cols={60}
                onChange={(e: any) => {
                  setJSONText(e.target.value);
                }}
              />
            </>
          )}
          <div>
            <select ref={elementTypeSelect}>
              <option value="text">text element</option>
              <option value="question-text">text question element</option>
              <option value="image">new Image</option>
              <option value="image-container">add new image container</option>
              <option value="video-container">add new video container</option>
              <option value="text-answer">add new text answer</option>
              <option value="graph"> add new graph </option>
              <option value="map-question"> add new map </option>
              <option value="question">add new question</option>
              <option value="youtube-link"> add new YouTube link</option>
              <option value="video">add new video</option>
            </select>
            <button onClick={() => addElement()}>add</button>
          </div>
          <div>
            <button disabled={saving} onClick={() => save()}>
              save
            </button>
          </div>
        </div>
      </div>
      <div>
        <input type="file" ref={fileField} />
        <button onClick={() => uploadBackground()}>upload</button>
      </div>
    </div>
  );
};

export default ChapterEditorComponent;
