import React from "react";
import orientationImage from "../../assets/rotate.svg";

function OrientationOverlay() {
  return (
    <div>
      <div className="orientation-background" />
      <div className="orientation-message">
        <div className="v-center">
          <span>Draai uw telefoon voor optimale weergave</span>
          <img src={orientationImage} alt="Draai uw telefoon" />
        </div>
      </div>
    </div>
  );
}

export default OrientationOverlay;
